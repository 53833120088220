<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="app">
		<AppHeader fixed>
			<SidebarToggler
				class="d-xl-none"
				display="lg"
				mobile />
			<SidebarToggler
				class="d-lg-down-none"
				display="xl" />
			<b-link
				:to="{name: 'MainHome'}"
				class="force-left-align navbar-brand">
				<div
					id="logo_navbar"
					class="navbar-brand-full d-none d-sm-block"
					alt="Logo" />
					<!-- <img
					class="navbar-brand-minimized"
					src="img/brand/sygnet.svg"
					width="30"
					height="30"
					alt="CoreUI Logo"> -->
			</b-link>
			<b-navbar-nav class="ml-auto">
				<Clock
					:compact="windowWidth === 'xs'"
					:class="windowWidth === 'xs' ? 'mr-3' : 'mr-4'" />
				<NotificationsAlert
					v-if="$user.auth() && $user.details().type === 'distributor'"
					class="mr-2" />
				<CountryDropdown
					v-if="!$user.auth()"
					class="mr-3"
					:class="(!$user.auth() || $user.details().type !== 'distributor') ? (windowWidth === 'xs' ? 'mr-2' : 'mr-3') : ''" />
				<LangDropdown />
				<AccountStepsList
					v-if="$user.auth() && $user.details().type === 'distributor'"
					:class="windowWidth === 'xs' ? 'mr-2 ml-1' : 'mr-3 ml-2'" />
				<DefaultHeaderDropdownAccnt />
			</b-navbar-nav>
		</AppHeader>
		<div class="app-body">
			<AppSidebar fixed>
				<SidebarHeader />
				<SidebarForm />
				<SidebarNav :nav-items="nav" />
				<SidebarFooter />
				<SidebarMinimizer />
			</AppSidebar>
			<main class="main">
				<Breadcrumb
					:class="['xs','sm'].includes(windowWidth) ? 'mb-0' : 'mb-3'"
					:list="translateList()" />
				<div
					:class="['xs','sm'].includes(windowWidth) ? 'p-2' : 'px-3'"
					class="container-fluid">
					<router-view :key="$route.fullPath.includes($router.resolve({ name: 'InventoryChanges'}).href) ? $router.resolve({ name: 'InventoryChanges'}).href : $route.fullPath" />
				</div>
			</main>
		</div>
		<TheFooter
			:class="{'mt-3' : ['md','lg','xl'].includes(windowWidth) }">
			<!--footer-->
			<div>
				<span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
				{{ appName }}
			</div>
			<div class="ml-auto">
				<span class="mr-1">{{ translate("powered_by") }}</span>
				<span><a
					:href="website"
					target="_blank">{{ poweredBy }}</a></span>
			</div>
		</TheFooter>
	</div>
</template>

<script>
/* eslint arrow-parens: ["error", "always"] */

import Vue from 'vue';
import {
	Header as AppHeader,
	SidebarToggler,
	Sidebar as AppSidebar,
	SidebarFooter,
	SidebarForm,
	SidebarHeader,
	SidebarMinimizer,
	SidebarNav,
	Footer as TheFooter,
	Breadcrumb,
} from '@coreui/vue';
import VCalendar, { setupCalendar } from 'v-calendar';
import nav from '@/router/menus/nav';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { admin as adminRoles, superAdmin, distributor } from '@/settings/Roles';
import Clock from '@/components/Clock';
import NotificationsAlert from '@/components/NotificationsAlert';
import isLoading from '@/components/Loading';
import { V_CALENDAR_CONFIG, RENEW_MEMBERSHIP_DAYS } from '@/settings/Dates';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import AccountStepsList from './AccountStepsList';
import LangDropdown from './LangDropdown';
import CountryDropdown from './CountryDropdown';
import { AVAILABLE_COUNTRIES } from '@/settings/MultiPayments';
import { ONLY_MEMBERS_COUNTRIES } from '@/settings/Country';
import { VALID_REDIRECTIONS } from '@/settings/AccountSteps';
import EventBus from '@/util/eventBus';
import { FINAL_STATUSES_CAN_LOGIN } from '@/settings/Statuses';
import { GIFT_ORDER_VALID_COUNTRIES } from '@/settings/GiftOrders';
import { FLOW_ROUTE } from '@/settings/Onboarding';

Vue.component('isLoading', isLoading);
Vue.use(VCalendar, V_CALENDAR_CONFIG);

export default {
	name: 'Full',
	components: {
		AppHeader,
		AppSidebar,
		TheFooter,
		Breadcrumb,
		Clock,
		NotificationsAlert,
		DefaultHeaderDropdownAccnt,
		SidebarForm,
		SidebarFooter,
		SidebarToggler,
		SidebarHeader,
		SidebarNav,
		SidebarMinimizer,
		LangDropdown,
		CountryDropdown,
		AccountStepsList,
	},
	mixins: [DocumentTitle, WindowSizes],
	data() {
		return {
			// nav: nav.items,
			appName: process.env.VUE_APP_TITLE,
			poweredBy: process.env.VUE_APP_POWERED,
			website: process.env.VUE_APP_WEBSITE,
			renewMembershipDays: RENEW_MEMBERSHIP_DAYS,
			distributor,
			validRedirections: VALID_REDIRECTIONS,
			onboardingFlowRoutes: FLOW_ROUTE,
		};
	},
	computed: {
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter((route) => route.name || route.meta.label);
		},
		nav() {
			try {
				let { type } = this.$user.details();
				if (adminRoles.includes(type)) {
					type = superAdmin;
				}
				return this.translateNav(nav[type.toLowerCase()].items);
			} catch (error) {
				return [];
			}
		},
		customScreenWidth() {
			if (this.windowWidthPx <= 767) {
				return 'xs';
			}
			if (this.windowWidthPx <= 991) {
				return 'sm';
			}
			if (this.windowWidthPx <= 1440) {
				return 'md';
			}

			return 'lg';
		},
	},
	watch: {
		language(newVal) {
			this.changeDocumentTitle();
			setupCalendar({
				locale: newVal,
			});
		},
	},
	created() {
		setupCalendar({
			locale: this.language,
		});
	},
	mounted() {
		EventBus.$on('scrollToReference', (id) => {
			this.scrollToReference(id);
		});
	},
	methods: {
		translateNav(menuItems) {
			const menu = JSON.parse(JSON.stringify(menuItems));
			return menu.filter((item) => {
				if (typeof item.children !== 'undefined') {
					// if it has children, call this function again
					item.children = this.translateNav(item.children);
					if (item.children.length < 1) {
						// after call this function, the item must have children, if not, return null
						return null;
					}
				}
				if (item.children instanceof Array) {
					item.name = this.translate(item.name + process.env.VUE_APP_MENU_POSTFIX);
					return item;
				}
				if (this.hasAccessToPath(item.url)) {
					if (this.$user.details().type === 'pre_distributor' && this.$user.details().status !== 'active' && item.name === 'store') {
						return null;
					}
					if (item.name === 'upgrade' && this.$user.details().hide_member_packs) {
						return null;
					}
					if (item.name === 'multiPaymentOrders' && (!this.$user.details().active_multi_payment && !adminRoles.includes(this.$user.details().type))) {
						return null;
					}
					if (item.name === 'multiPaymentOrders' && this.$user.details().country !== undefined && !AVAILABLE_COUNTRIES.includes(this.$user.details().country.iso_code_2)) {
						return null;
					}
					if (item.name === 'customCarts' && this.$user.details().country !== undefined && ONLY_MEMBERS_COUNTRIES.includes(this.$user.details().country.iso_code_2)) {
						return null;
					}
					if (item.name === 'giftOrders' && this.$user.details().country !== undefined && !GIFT_ORDER_VALID_COUNTRIES.includes(this.$user.details().country.iso_code_2)) {
						return null;
					}
					if (adminRoles.includes(this.$user.details().type) && this.$user.details().country !== undefined && ['bv', 'refunds'].includes(item.name)) {
						return null;
					}
					// string to show in menu
					item.name = this.translate(item.name + process.env.VUE_APP_MENU_POSTFIX);
					if (item.badge) {
						item.badge.text = this.translate(item.badge.text);
					}
					return item;
				}
				return null;
			});
		},
		scrollToReference(referenceCode) {
			if (Object.keys(this.validRedirections).includes(referenceCode) && this.$route.name !== this.validRedirections[referenceCode].name) {
				const route = { name: this.validRedirections[referenceCode].name };
				this.$router.push(route);
			}

			const { height } = this.validRedirections[referenceCode];
			this.$nextTick(() => {
				const elementPosition = height[this.customScreenWidth];
				const offsetPosition = elementPosition;
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth',
				});
				if (this.validRedirections[referenceCode].emit !== null) {
					const event = this.validRedirections[referenceCode].emit;
					EventBus.$emit(event.name, event.emitData);
				}
			});
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (typeof FINAL_STATUSES_CAN_LOGIN[vm.$user.details().status] !== 'undefined') {
				vm.$router.replace({ name: 'AccountCanceled' });
			} else if (vm.$user.details().force_username_change) {
				vm.$router.replace({ name: 'ForceUsernameChange' });
			} else if (vm.$user.details().remaining_days_terminated === vm.renewMembershipDays && vm.$user.details().type === vm.distributor) {
				vm.$router.replace({ name: 'MembershipRenewal' });
			} else if (vm.$user.details().pay_transfer_account_fee) {
				vm.$router.replace({ name: 'AccountTransferFee' });
			} else if (to.name.indexOf('CustomCarts') !== -1 && vm.$user.details().country !== undefined && ONLY_MEMBERS_COUNTRIES.includes(vm.$user.details().country.iso_code_2)) {
				vm.$router.replace({ name: 'Home' });
			} else if (vm.$user.details().force_redirect_to) {
				const flowCode = vm.$user.details().force_redirect_to;
				const redirectRoute = vm.onboardingFlowRoutes.find((element) => element.flow_code === flowCode);
				if (redirectRoute) {
					vm.$router.replace({ name: redirectRoute.route_name });
				}
			}
		});
	},
};
</script>
<style scoped>
@media (max-width: 992px) {
	.force-left-align.navbar-brand {
		position: static;
		left: 0;
		margin-left: 0;
	}
}
</style>
